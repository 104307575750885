<template>
  <div>
    <b-container class="container-box">
      <b-row class="no-gutters">
        <b-col>
          <h1 class="font-weight-bold header-main text-uppercase mb-3">
            ข้อมูลอะคาเดมี่
          </h1>
        </b-col>
      </b-row>

      <div class="bg-white p-3" v-if="$isLoading">
        <b-row class="pl-1">
          <b-col cols="6">
            <div class="panel d-flex align-items-md-center">
              <!-- <b-form-checkbox size="lg" id="highlight"
                >เป็นไฮไลท์</b-form-checkbox
              > -->
              <b-form-checkbox
                size="lg"
                id="sameLang"
                class="mt-0 mt-sm-3"
                @change="useSameLanguage"
                v-model="form.isSameLanguage"
                >ใช้เหมือนกันทุกภาษา
              </b-form-checkbox>
            </div>
          </b-col>
          <b-col cols="6" class="text-right">
            <div class="panel">
              <b-button
                type="button"
                class="btn btn-language"
                v-for="(language, index) in languageList"
                v-bind:key="index"
                v-bind:class="[languageActive == language.id ? 'active' : '']"
                @click="changeLanguage(language.id, index)"
                :disabled="form.isSameLanguage ? true : false"
              >
                <span class="text-uppercase">{{ language.nation }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col md="6">
            <div
              v-for="(item, index) in form.translationList"
              v-bind:key="index"
            >
              <div
                v-bind:class="[
                  languageActive == item.languageId ? '' : 'd-none',
                ]"
              >
                <InputText
                  textFloat="ชื่อบทความ"
                  placeholder="ชื่อบทความ"
                  type="text"
                  name="name"
                  :img="imageLogoLang"
                  v-model="item.name"
                  isRequired
                  :isValidate="$v.form.translationList.$each.$iter[index].name.$error"
                  :v="$v.form.translationList.$each.$iter[index].name"
                />
              </div>
            </div>
          </b-col>
          <b-col md="6">
            <InputSelect
              class=""
              classLabelName="col-lg-4 my-auto"
              classInputName="col-lg-6"
              title="หัวข้อที่เลือก"
              name="academyId"
              valueField="id"
              textField="name"
              v-bind:options="topicLists"
              v-model="form.academyId"
              @onDataChange="(value) => form.academyId = value"
              isRequired
              :v="$v.form.academyId"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <InputText
              textFloat="วิดิโอลิงค์"
              placeholder="https://www.youtube.com/embed/xxxxxxx"
              type="text"
              name="linkurl"
              v-model="form.linkUrl"
              isRequired
              @onKeyup="replaceLink"
              @input="replaceLink"
            />
            <InputText
              textFloat="ลำดับการแสดง"
              placeholder="ลำดับการแสดง"
              type="text"
              name="sortorder"
              @onKeypress="isNumber($event)"
              v-model="form.sortOrder"
            />
          </b-col>
          <b-col md="6">
            <UploadFile
              textFloat="ภาพปก"
              placeholder="กรุณาเลือกไฟล์เพื่ออัพโหลด"
              format="image"
              name="thumbnail"
              :fileName="fileName"
              text="*กรุณาอัพโหลดไฟล์สกุล .png, .jpg ขนาด 4:3 ขนาดไม่เกิน 50 MB"
              isRequired
              v-on:onFileChange="onImageChange"
              v-on:delete="deleteImage"
              :v="$v.fileName"
            />
            <div
              class="preview-box ratio-4-3-pb"
              v-bind:style="{ 'background-image': 'url(' + showPreview + ')' }"
            >
              <img
                src="/img/loading.svg"
                class="loading"
                alt="loading"
                v-if="isLoadingImage"
              />
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col md="12">
            <div
              v-for="(item, index) in form.translationList"
              v-bind:key="index"
            >
              <div
                v-bind:class="[
                  languageActive == item.languageId ? '' : 'd-none',
                ]"
              >
                <TextEditor
                  textFloat="รายละเอียด"
                  :rows="8"
                  :value="item.description"
                  :name="'description_' + item.languageId"
                  :img="imageLogoLang"
                  placeholder="รายละเอียด"
                  isRequired
                  :isValidate="$v.form.translationList.$each.$iter[index].description.$error"
                  :v="$v.form.translationList.$each.$iter[index].description"
                  @onDataChange="(val) => (item.description = val)"
                  :imgTypeId="imgTypeId"
                />
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <b-col>
            <label class="font-weight-bold main-label">
              สถานะการแสดงผล
              <span class="text-danger">*</span>
            </label>
            <div>
              <b-form-checkbox
                switch
                v-model="form.display"
                class="radio-active"
                size="lg"
              >
                <span class="ml-2 main-label">{{
                  form.display ? "ใช้งาน" : "ไม่ใช้งาน"
                }}</span>
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-5">
          <b-col md="6">
            <b-button
              v-if="isEdit"
              class="btn btn-danger btn-details-set mr-md-2"
              :disabled="isDisable"
              @click="openModalDelete(form.translationList[0].name)"
              >ลบ</b-button
            >
            <b-button
              href="/academy"
              :disabled="isDisable"
              class="btn-details-set btn-cancel"
              >ย้อนกลับ</b-button
            >
          </b-col>
          <b-col md="6" class="text-sm-right">
            <button
              type="button"
              @click="checkForm(0)"
              :disabled="isDisable"
              class="btn btn-main btn-details-set ml-md-2 text-uppercase"
            >
              บันทึก
            </button>
            <button
              type="button"
              @click="checkForm(1)"
              :disabled="isDisable"
              class="btn btn-main btn-details-set ml-md-2 text-uppercase"
            >
              บันทึกและออก
            </button>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalAlertConfirm
      msg="ยืนยันการลบ ?"
      :text="modalMessage"
      btnConfirm="ลบ"
      colorBtnConfirm="danger"
      btnCancel="ยกเลิก"
      ref="isModalAlertConfirm"
      @confirm="btnDelete"
    />
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import UploadFile from "@/components/inputs/UploadFile";
import { required, numeric } from "vuelidate/lib/validators";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import TextEditor from "@/components/inputs/TextEditor";
import Vue from "vue";

export default {
  name: "AcademyDetails",
  components: {
    InputText,
    UploadFile,
    ModalAlert,
    ModalAlertError,
    ModalAlertConfirm,
    ModalLoading,
    InputSelect,
    TextEditor
  },
  data() {
    return {
      id: this.$route.params.id,
      isLoadingImage: false,
      coverImgType: 1,
      showVideo: "",
      showPreview: "",
      isEdit: false,
      images: "",
      modalMessage: "",
      isDisable: false,
      fileName: "",
      languageList: [],
      languageActive: 1,
      imageLogoLang: "",
      form: {
        academyId: 0,
        deleted: false,
        display: true,
        id: 0,
        imageUrl: "",
        isSameLanguage: false,
        linkUrl: "",
        mainLanguageId: 1,
        sortOrder: 0,
        isSameLanguage: false,
        translationList: [
          {
            languageId: 1,
            name: "",
            description: "",
          },
          {
            languageId: 2,
            name: "",
            description: "",
          },
        ],
      },
      imgTypeId: 25,
      topicLists: [
        {id: 0, name: "กรุณาเลือกหัวข้อ"}
      ]
    };
  },
  validations: {
    fileName: { required },
    form: {
      sortOrder: { numeric },
      translationList: {
        $each: {
          name: { required },
          description: { required },
        },
      },
    },
  },
  created: async function () {
    await this.getAcademyArticle();
    await this.getDatas();
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    changeSameLang: function (value) {
      this.form.isSameLanguage = value;
    },
    getAcademyArticle: async function () {
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/academy/GetAllList`,
        null,
        this.$headers,
        null
      );
      if (resData.result == 1) {
        this.topicLists = this.topicLists.concat(resData.detail.dataList);
      }
    },
    getDatas: async function () {
      this.$isLoading = false;

      let languages = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/language`,
        null,
        this.$headers,
        null
      );
      if (languages.result == 1) {
        this.languageList = languages.detail;
        this.changeLanguage(1, 0);
      }
      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/academy/academyArticle/${this.id}`,
        null,
        this.$headers,
        null
      );

      if (data.result == 1) {
        this.form = data.detail.academyArticle;

        //this.$v.form.$reset();

        if (this.form.id > 0) {
          this.isEdit = true;
          this.showPreview = this.form.imageUrl;
          this.fileName = this.form.imageUrl;
          this.replaceLink();
        }else{
          this.form.isSameLanguage = true;
          this.form.mainLanguageId = 1;
        }
      }

      if (this.form.isSameLanguage) {
        this.imageLogoLang = "";
      } else {
        var index = this.languageList
          .map(function (x) {
            return x.id;
          })
          .indexOf(this.languageActive);
        this.imageLogoLang = this.languageList[index].imageUrl;
      }
      this.$isLoading = true;
    },
    changeLanguage(id, index) {
      this.languageActive = id;
      this.imageLogoLang = this.languageList[index].imageUrl;
    },
    onImageChange(img) {
      this.isLoadingImage = true;
      this.isDisable = true;

      var reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onload = async () => {
        //this.images = reader.result;
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoadingImage = false;
        this.isDisable = false;

        this.showPreview = this.images;
        this.form.imageUrl = this.images;
        this.fileName = this.images;

        if (img.type == "video/mp4") {
          this.coverImgType = 2;
          var vid = this.$refs.videoRef;
          if (vid != undefined) {
            vid.load();
          }
        } else {
          this.coverImgType = 1;
        }
      };
    },
    saveImagetoDb: async function (img) {
      var imgData = {
        base64: img,
        type: this.imgTypeId
      };

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/image/save`,
        null,
        this.$headers,
        imgData
      );

      if (data.result == 1) {
        return data.detail.url;
      }
    },
    deleteImage() {
      this.form.imageUrl = null;
      this.form.imageBase64 = null;
      this.fileName = "";
      this.showPreview = null;

      if (this.coverImgType == 2) {
        var vid = this.$refs.videoRef;
        vid.load();
        this.coverImgType = 1;
      }
    },
    checkForm: async function (flag) {
      if (this.form.isSameLanguage) {
        await this.useSameLanguage();
      }
      this.$v.$touch();
      if (this.$v.$error) {
        await this.checkValidatetranslationList();
        return;
      }
      this.modalAlertShow = false;
      this.flag = flag;
      this.submit();
    },
    submit: async function () {
      this.isDisable = true;
      this.$refs.modalLoading.show();
      this.replaceLink();

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/academy/academyArticle/save`,
        null,
        this.$headers,
        { academyArticle: this.form }
      );

      this.modalMessage = data.message;
      this.isDisable = false;
      this.$refs.modalLoading.hide();
      if (data.result == 1) {
        this.existId = data.detail;
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();

        if (this.flag == 1) {
          setTimeout(() => {
            this.$router.push({
              path: `/academy`,
            });
          }, 3000);
        } else {
          setTimeout(() => {
            this.$refs.modalAlert.hide();
          }, 3000);
          if (this.id > 0) {
            this.getDatas();
          } else {
            this.form.id = this.existId;
            this.id = this.existId;
            this.isEdit = true;
            this.$router.push({ path: `/academy/details/${this.existId}` });
          }
        }
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    useSameLanguage: async function () {
      Vue.nextTick(() => {
        if (this.form.isSameLanguage) {
          this.imageLogoLang = "";
          this.form.mainLanguageId = this.languageActive;
          let data = this.form.translationList.filter(
            (val) => val.languageId == this.form.mainLanguageId
          );

          //if (this.id == 0) {
          if (data.length == 1) {
            data = data[0];
            for (
              let index = 0;
              index < this.form.translationList.length;
              index++
            ) {
              this.form.translationList[index].name = data.name;
              this.form.translationList[index].description = data.description;
            }
          }
          //}
        } else {
          var index = this.languageList
            .map(function (x) {
              return x.id;
            })
            .indexOf(this.languageActive);
          this.imageLogoLang = this.languageList[index].imageUrl;

          let data = this.form.translationList.filter(
            (val) => val.languageId != this.form.mainLanguageId
          );
          if (this.id == 0) {
            if (data.length == 1) {
              data = data[0];
              data.name = "";
              data.description = "";
            }
          }
        }
      });
    },
    checkValidatetranslationList: async function () {
      let isError = false;
      this.languageList.forEach((element, index) => {
        if (!isError) {
          if (this.$v.form.translationList.$each.$iter[index].$error) {
            this.languageActive = this.$v.form.translationList.$model[
              index
            ].languageId;

            this.imageLogoLang = this.languageList[index].imageUrl;

            isError = true;
          }
        }
      });
    },
    btnDelete: async function () {
      this.$refs.isModalAlertConfirm.hide();

      let resData = await this.$callApi(
        "delete",
        `${this.$baseUrl}/api/academy/academyArticle/delete/${this.id}`,
        null,
        this.$headers,
        null
      );
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$router.push({
            path: `/academy`,
          });
        }, 3000);
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    openModalDelete(name) {
      this.modalMessage = "คุณต้องการลบ " + name + " ใช่หรือไม่?";
      this.$refs.isModalAlertConfirm.show();
    },
    replaceLink(){
      this.form.linkUrl = this.form.linkUrl.replace("https://youtu.be", "https://www.youtube.com/embed");
    }
  },
};
</script>

<style scoped>
.panel {
  font-size: 17px;
  color: #16274a;
}
</style>
